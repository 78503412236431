import React, {useState, useEffect} from "react"
import styled from "styled-components"
import InputComp from "components/InputComp"
import Button from 'components/Button'
import { formatDollar, formatNumberComma } from "utils/helperfunctions"
import { fetchWrapper } from "utils/apihandlers"
import CustomModal from 'components/customModal'
import { monthNames, getLine, formatDate, getFirstTuesdayOfMonth } from 'utils/helperfunctions'
import SelectComp from "components/SelectComp"
import AddNewListing from "components/addnewlistingcomp"
import DisplayListingComps from "components/displaylistingscomp/displaylistingscomp"
import LoadingComponent from 'components/LoadingComponent';
import { userContext } from "usercontext"
import LocalNewsComp from "components/LocalNewsComp"

const ForeclosureComp:React.FC = () => {
    const [ countyselectoptionid, setcountyselectoptionid ] = useState(-1)

    const countynames = ['-select county-',
      'Bexar County',
      'Collin County',
      'Dallas County',
      'Denton County',
	  'FortBend County',
      'Harris County',
      'Kaufman County',
      'Rockwall County',
      'Tarrant County',
      'Travis County',
      // 'Ellis County',
      // 'Hunt County',
      // 'Kaufman County',
      // 'Johnson County',
      // 'Parker County',
      // 'Wise County',
    ]

    const [ legaldescription, setlegaldescription ] = useState("")
    
    const [ address, setaddress ] = useState("")
    const [ auctiondate, setauctiondate ] = useState("")
    const [ loading, setloading ] = useState(false)
    const [ loadingdeed, setloadingdeed ] = useState(false)
    
    const [ selectedprop, setselectedprop ] = useState(-1)
    const [ selectedIdx, setselectedIdx ] = useState(-1)
    const [ properties, setproperties ] = useState<any>([])
    const [ propid, setpropid ] = useState(-1)  
    
    const [ lienamount, setlienamount ] = useState("")
    const [ lienyear, setlienyear ] = useState("")
    const [ assessedValue, setassessedValue ] = useState("")
    const [ improvementarea, setimprovementarea ] = useState("")
    const [ ownername, setownername ] = useState("")
    const [ propertytype, setpropertytype ] = useState("")
    
    const [ deednumber, setdeednumber ] = useState("")
    const [ deeddate, setdeeddate ] = useState("")
    const [ propertyid, setpropertyid ] = useState("")
    
    const [ propertylink, setpropertylink ] = useState("")
    const [ propdesc, setpropdesc ] = useState("")
    const [ street, setstreet ] = useState("")
    const [ addrcity, setaddrcity ] = useState("")
    const [ state, setstate ] = useState("")
    const [ zip, setzip ] = useState("")
    const [ yearbuilt, setyearbuilt ] = useState("")
    

    const [ trusteename, settrusteename ] = useState("")
    const [ trusteephone, settrusteephone ] = useState("")

    const [ favorite, setfavorite ] = useState(false)
    const [ searchfavorites, setsearchfavorites ] = useState(false)    
    const [ imageurl, setimageurl ] = useState<any>(null)
    const [ currentimageidx, setcurrentimageidx ] = useState(-1)
    
    const [ notice, setnotice ] = useState("")
    const [ deedsearchtext, setdeedsearchtext ] = useState("")
    const [ beds, setbeds ] = useState("")
    const [ baths, setbaths ] = useState("")
    
    const [modalState, setModalState] = useState(false)
    const [modalHeader, setModalHeader] = useState('')
    const [modalMessage, setModalMessage] = useState('')
    const [modalprogress, setmodalprogress] = useState(false)

    const [ showlistingtable, setshowlistingtable ] = useState(true)
    const [ showAddListingComp, setshowAddListingComp ] = useState(false)
    const [ showAddLocalcomp, setshowAddLocalcomp ] = useState(false)
    

    const openModal = (header: string, message: string, progress?: boolean) => {
      setModalHeader(header)
      setModalMessage(message)
      setmodalprogress(progress?true: false)
      setModalState(true)
    }


    const selectProp = (idx: any) => {
        setselectedIdx(idx)
        setaddress(`${properties[idx].street}, ${properties[idx].city}, ${properties[idx].state}, ${properties[idx].zip}`)
        setlegaldescription(`${properties[idx].legaldescription}`)
        setlienamount(properties[idx].defaultamount?properties[idx].defaultamount:"")
        setlienyear(properties[idx].lienyear?properties[idx].lienyear:"")
        setpropdesc(properties[idx].description?properties[idx].description:"")
        setassessedValue(properties[idx].appraisedvalue)
        setimprovementarea(properties[idx].improvementarea)
        setownername(properties[idx].ownername?properties[idx].ownername:"")
        setpropertytype(properties[idx].type)
        setdeednumber(properties[idx].deednumber)
        setdeeddate(properties[idx].deeddate)
        setpropertylink(properties[idx].propertyid?`http://www.collincad.org/propertysearch?prop_id=${properties[idx].propertyid}`:"http://www.collincad.org/propertysearch")
        setpropertyid(properties[idx].propertyid)
        getAddress(`${properties[idx].street}, ${properties[idx].city}, ${properties[idx].state}, ${properties[idx].zip} `)
        settrusteename(properties[idx].trusteename?properties[idx].trusteename:"")
        settrusteephone(properties[idx].trusteephone?properties[idx].trusteephone:"")
        setfavorite(properties[idx].favorite)
        setyearbuilt(properties[idx].yearbuilt)
        setdeedsearchtext(properties[idx].deednumber)
        setnotice(properties[idx].foreclosurenotice?properties[idx].foreclosurenotice:"")
        setbeds(properties[idx].beds?properties[idx].beds:"")
        setbaths(properties[idx].baths?properties[idx].baths:"")

        if(properties[idx].images && properties[idx].images.length>0){
          let response: any = showImage(properties[idx].images[0])
          setcurrentimageidx(0)
        }else{
          setimageurl(null)
          setcurrentimageidx(-1)
        }

    }

    const showpreviousimage = async () => {
      if((currentimageidx-1)>=0){
        await showImage(properties[selectedIdx].images[currentimageidx-1])
        setcurrentimageidx(currentimageidx-1)
      }
    }

    const shownextimage = async () => {
      if((currentimageidx+1)<properties[selectedIdx].images.length){
        await showImage(properties[selectedIdx].images[currentimageidx+1])
        setcurrentimageidx(currentimageidx+1)
      }
    }

    const showImage = async (imagename: any) => {
      let body = {
        imagename:imagename
      }
      // console.log(`body : ${JSON.stringify(body)}`)
      try{
        var response = await fetchWrapper.post("/api/admin/showimage", {imagename: imagename}).then((r: any)=>r)
        setimageurl(response.data.url)
      }catch(error){}

      
    }

    const getAddress = (address: any) => {
      const regex = /([\dA-Za-z\s]+)[,]?[\s+]?([A-Za-z\s]+)[,]?[\s+]?([A-Za-z]{2})[,]?[\s+]?(\d{5})/i;
      // const regex = /([0-9A-Za-z\s]+),\s*([A-Za-z\s]+),\s*([A-Z]{2}),\s*(\d{5})/;
      const match = address.match(regex);
      let info: any = {}
      if (match) {
          setstreet(match[1].toUpperCase())
          setaddrcity(match[2].toUpperCase())
          setstate(match[3].toUpperCase())
          setzip(match[4].toUpperCase())
          // console.log(`here ${match.join("-")}`)
      }else{
        setstreet("")
        setaddrcity("")
        setstate("")
        setzip("")
      }
      
    }

    const getincompleteforeclosureprops = async () => {
      if(countyselectoptionid<1){
        openModal("Warning", "Please select county before continuing", false)
        return
      }
      setloadingdeed(true)
      setpropid(-1)

      var response = await fetchWrapper.post("/api/admin/foreclosureapi/getincompleteforeclosureprops", {county: countynames[countyselectoptionid], auctiondate: auctiondate}).then(r=>r)
      if(response.data.props.length-1 < selectedIdx){
        setselectedIdx(-1)
        setselectedprop(-1)
      }

      setproperties(response.data.props)
      setloadingdeed(false)

    }

    const labledeedexists = async (props: any) => {
        for(let i=0; i<props.length; i++){
          let deedexists = await checkDeedFileExists(props[i])
          props[i].deedexists = deedexists
        }
        return props
    }

    const addListing = async () => {
      if(countyselectoptionid<1){
        openModal("Warning", "Please select county before continuing", false)
        return
      }
      if(auctiondate==""){
        openModal("Warning", "Please enter auction date before continuing (MM/YYYY)", false)
        return
      }
      setshowAddListingComp(true)
      localStorage.setItem("addlisting", "1")
      setproperties([])
      setselectedIdx(-1)
      setselectedprop(-1)
    }

    const addLocal = async () => {
      if(auctiondate==""){
        openModal("Warning", "Please enter auction date before continuing (MM/YYYY)", false)
        return
      }
      setshowAddLocalcomp(true)
      setshowAddListingComp(false)
      setshowlistingtable(false)
    }

    const loadproperties = async () => {
      setproperties([])
      setshowAddListingComp(false)
      setshowlistingtable(true)
      setshowAddLocalcomp(false)
      // localStorage.setItem("addlisting", "0")

      if(countyselectoptionid<1){
        openModal("Warning", "Please select county before continuing", false)
        return
      }
      if(auctiondate==""){
        openModal("Warning", "Please enter auction date before continuing (MM/YYYY)", false)
        return
      }
      setloadingdeed(true)
      setpropid(-1)
      try{
        var response: any = await fetchWrapper.post("/api/foreclosureapi/getforeclosureprops", {auctiondate: auctiondate, county: countynames[countyselectoptionid]}).then(r=>r)
      
        if(response.data.props.length-1 < selectedIdx){
          setselectedIdx(-1)
          setselectedprop(-1)
        }
        setproperties(response.data.props)
        setloadingdeed(false)
      }catch(error){
        openModal("Warning", "Something unexpected happened, please logout and login again.", false)
        setloadingdeed(false)
        userContext.logout()
      }
      
    }

    const loadIncompleteproperties = async () => {
      console.log(`loading incomplete! ${countynames[countyselectoptionid]}`)
      
      setproperties([])
      setshowAddListingComp(false)
      setshowlistingtable(true)
      setshowAddLocalcomp(false)
      // localStorage.setItem("addlisting", "0")

      if(countyselectoptionid<1){
        openModal("Warning", "Please select county before continuing", false)
        return
      }
      if(auctiondate==""){
        openModal("Warning", "Please enter auction date before continuing (MM/YYYY)", false)
        return
      }
      setloadingdeed(true)
      setpropid(-1)
      try{
        var response: any = await fetchWrapper.post("/api/foreclosureapi/getforeclosureprops", {auctiondate: auctiondate, county: countynames[countyselectoptionid], nopropids:"noprop"}).then(r=>r)
      
        if(response.data.props.length-1 < selectedIdx){
          setselectedIdx(-1)
          setselectedprop(-1)
        }
        setproperties(response.data.props)
        setloadingdeed(false)
      }catch(error){
        openModal("Warning", "Something unexpected happened, please logout and login again.", false)
        setloadingdeed(false)
        userContext.logout()
      }
      
    }

    const loadenter = (e: any) =>{
      if(e.key === "Enter"){
        loadIncompleteproperties()
      }
    }
    const insertdocblock = () => {
      let deedsearchtext = `[{
        "header":"",
        "documentNumber":"",
        "recordDate":"",
        "grantor":"",
        "grantee":"",
        "legaldesc":"",
        "valididx":,
        "documentStatus":"",
        "numberOfPages":"",
        "bookVolumePage":"",
        "instrumentDate":"",
        "description":"",
        "deeddocfileid":"",
        "doclinktag":"",
        "consideration":"[]"
      }]`
      setdeedsearchtext(deedsearchtext)
    }

  const changecountyselectoption = (e: any) => {
    setcountyselectoptionid(e.target.value)
    // localStorage.setItem("county", countynames[e.target.value])
  }

  const getdeedsearchlink = (county: any) => {
    switch(county){
		case "Collin County":
			return "https://collin.tx.publicsearch.us/"
		case "Bexar County":
			return "https://bexar.tx.publicsearch.us/"
		case "Dallas County":
			return "https://dallas.tx.publicsearch.us/"
		case "Denton County":
			return "https://denton.tx.publicsearch.us/"
		case "FortBend County":
			return "https://ccweb.co.fort-bend.tx.us/RealEstate/SearchEntry.aspx"
		case "Harris County":
			return "https://www.cclerk.hctx.net/Applications/WebSearch/RP.aspx"
		case "Kaufman County":
			return "https://kaufmancountytx-web.tylerhost.net/web/search/DOCSEARCH1008S7"
		case "Rockwall County":
			return "https://rockwalltx-web.tylerhost.net/web/search/DOCSEARCH144S1"
		case "Tarrant County":
			return "https://tarrant.tx.publicsearch.us/"
		case "Travis County":
			return "https://www.tccsearch.org/RealEstate/SearchEntry.aspx"
		default:
			return "no county selected"
    }
  }

  const checkDeedFileExists = async (property: any) =>{

    let response = await fetchWrapper.post("/api/admin/foreclosureapi/checkDeedFileExists", {filename:`${property.foreclosureid}.txt.zip`}).then((r: any) => r)
    // console.log(`response.data : ${JSON.stringify(response.data)}`)
    
    if(response.data.fileexists!=200){
      return false
    }
    return true
  }

  const getpropertycadlink = (county: any) => {
    switch(county){
		case "Collin County":
			return "http://www.collincad.org/propertysearch"
		case "Bexar County":
			return "https://bexar.trueautomation.com/clientdb/propertysearch.aspx?cid=110"
		case "Dallas County":
			return "https://www.dallascad.org/SearchOwner.aspx"
		case "Denton County":
			return "https://www.dentoncad.com/property-search"
		case "FortBend County":
			return "https://esearch.fbcad.org/"
		case "Harris County":
			return "https://hcad.org/property-search/real-property-advanced-records/"
		case "Kaufman County":
			return "https://www.kaufmancad.org/"
		case "Tarrant County":
			return "https://www.tad.org/"
		case "Rockwall County":
			return "https://rockwallcad.com/property-search"
		case "Travis County":
			return "https://travis.prodigycad.com/property-search"
		default:
			return "no county selected"
    }
  }

  const getforeclosurelink = (county: any) => {

    switch(county){
		case "Collin County":
			return "https://apps2.collincountytx.gov/ForeclosureNotices"
		case "Bexar County":
			return "https://bexar.tx.publicsearch.us/results?department=FC&limit=50&searchOcrText=false&searchType=quickSearch&sort=desc"
		case "Dallas County":
			return "https://www.dallascounty.org/government/county-clerk/recording/foreclosures.php"
		case "Denton County":
			return "https://denton.tx.publicsearch.us/"
		case "FortBend County":
			return "https://www.fortbendcountytx.gov/government/departments/county-clerk/search-for-foreclosures"
		case "Harris County":
			return "https://www.cclerk.hctx.net/Applications/WebSearch/Registration/Login.aspx?ReturnUrl=%2fApplications%2fWebSearch%2fFRCL_R.aspx"
		case "Kaufman County":
			return "https://www.kaufmancounty.net/383/Foreclosures"
		case "Tarrant County":
			return "https://tarrant.tx.publicsearch.us/results?department=FC&limit=50&searchOcrText=false&searchType=quickSearch&sort=desc&sortBy=saleDate"
		case "Rockwall County":
			return "https://www.rockwallcountytexas.com/792/Foreclosure-Notices"
		case "Travis County":
			return "https://www.tccsearch.org/RealEstate/SearchEntry.aspx"
		default:
			return "no county selected"
    }


  }
  useEffect(()=>{

    // let addlisting = localStorage.getItem("addlisting")
    // if(addlisting&&addlisting=="1"){
    //   setshowAddListingComp(true)
    // }else{
    //   setshowAddListingComp(false)
    // }
    let dt = new Date()
    let mth = dt.getMonth()
    let year = dt.getFullYear()
    let auctiondate = getFirstTuesdayOfMonth(mth+1, year)
    let auc_dt_arr = auctiondate.split("/")
    let auction_dt = new Date(Number(auc_dt_arr[2]), Number(auc_dt_arr[0])-1, Number(auc_dt_arr[1])+1,0,0,0)
    if(auction_dt.getTime()<dt.getTime()){
      if(mth<11){
        // console.log(`mth less than december!`)          
        auctiondate = getFirstTuesdayOfMonth(mth+2,year)
      }else{
        auctiondate = getFirstTuesdayOfMonth(1,year+1)
      }
    }
    let auction_date = auctiondate.split("/")
    setauctiondate(`${auction_date[0]}/${auction_date[2]}`) 
  }, [loading])

    useEffect(()=>{
      if(loading)return
      setloading(true)
    }, [])

    return (
        <MainContainer>
          <CustomModal
            state={modalState}
            header={modalHeader}
            message={modalMessage}
            progress={modalprogress}
            handleClose={() => {
              setModalState(false)
            }}
          />
              <Column>
                <Header>Foreclosures</Header>

                
                <ButtonContainer onKeyUp={(e) => loadenter(e)} >
                    <SelectComp id="countyselectid" optionid={countyselectoptionid} labels={countynames} onChange={changecountyselectoption} width={150} placeholder="-select county-"/>
                    <InputComp id= "searchauctiondateid" placeholder="Auction Date (mm/yyyy)" value={auctiondate} onChange={(e)=>setauctiondate(e.target.value)} width={150} />

                </ButtonContainer>
                
                <ButtonContainer>
                  <Button text="Load" handleClick={()=>loadproperties()}/>
                  <Button text="Load Incomplete" handleClick={()=>loadIncompleteproperties()}/>
                  <Button text="Add Listing" handleClick={()=>addListing()} />
                  {/* <Button text="Local News" handleClick={()=>addLocal()} /> */}
                </ButtonContainer>

                {showlistingtable || showAddListingComp ?<><Header>Useful links</Header>
                <Column>
                  <Row>
                    <InfoHeader>Foreclosures</InfoHeader>
                    <Link href={getforeclosurelink(countynames[countyselectoptionid])} target="_blank">{getforeclosurelink(countynames[countyselectoptionid])}</Link>
                  </Row>
                  <Row>
                    <InfoHeader>Property Information</InfoHeader>
                    <Link href={getpropertycadlink(countynames[countyselectoptionid])} target="_blank">{getpropertycadlink(countynames[countyselectoptionid])}</Link>
                  </Row>
                  <Row>
                    <InfoHeader>Deed Search</InfoHeader>
                    <Link href={getdeedsearchlink(countynames[countyselectoptionid])} target="_blank">{getdeedsearchlink(countynames[countyselectoptionid])}</Link>
                  </Row>
                </Column>
                  </>
                :<></>}
                <div style={{alignSelf:'center'}}>
                  {loadingdeed?
                    <Column style={{alignItems: 'center'}}>
                    <LoadingComponent />

                    </Column>
                    :
                    <></>
                  }
                </div>

                {showAddListingComp?
                  <AddNewListing county={countynames[countyselectoptionid]} auctiondate={auctiondate} openModal={openModal} closeModal={()=>setModalState(false)}
                  /> :<></>}
                {showlistingtable?
                <DisplayListingComps openModal={openModal} properties={properties} setproperties={setproperties} countyname={countynames[countyselectoptionid]} setloading={setloading}/>
                :<></>
                
                }
                {showAddLocalcomp?
                  <LocalNewsComp openModal={openModal} setloading={setloading}
                  />:<></>
                }

              </Column>
        </MainContainer>
    )
}

const MainContainer = styled.div`
  margin: 25px;
  display: flex;
  border-radius: 3px;
  width: 90%;
  color: #000000;
  min-height: 50vh;
`
const Column = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
`
const Header = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: 700;
  color: black;
`
const Prop = styled.div`
  display: flex;
  border: 1px solid gray;
  justify-content: center;
  align-items: center;
  @media (max-width: 990px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  &:hover {
    background-color: #f0f0f0;
  }
`

const Item = styled.div`
  display: flex;
  padding: 5px;
  font-size: 12px;
  flex-wrap: wrap;
  overflow: hidden;
  height: auto;
  align-items: center;
  word-break: wrap;
  // color: black;
`

const InfoHeader = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  margin: 3px 10px 0 0;
  align-items: center;
`;

const InfoItem = styled.div`
  margin: 3px 0;
  display: flex;
  font-size: 14px;
  font-weight: 300;
  align-items: center;
`;

const Link =  styled.a`
  margin: 7px;
  cursor: pointer;
  color: #3366CC;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export default ForeclosureComp;
